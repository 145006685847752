blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre { margin: 0; }
h1, h2, h3, h4, h5, h6 { font-size: inherit; font-weight: inherit; }
ol, ul { list-style: none; margin: 0; padding: 0; }
img, svg, video, canvas, audio, iframe, embed, object { display: block; }

table { border-collapse: collapse; }
input { font: inherit; margin: 0; color: inherit; }
button { background: transparent; padding: 0; margin: 0; border: 0; outline: 0; font: inherit; color: inherit; cursor: pointer; }
a { padding: 0; margin: 0; font: inherit; color: inherit; text-decoration: none; cursor: pointer; }
[role=button] { cursor: pointer; }
:-moz-focusring { outline: 0 none; }
