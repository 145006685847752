* {
  outline: 0; /* TODO: add outline styles */
}

html {
  background: #1c1c1c;
  font-size: 62.5%;
}

body {
  font-family: 'Inter', sans-serif;
  font-size: 1.2rem;
  line-height: 1.4;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3 { font-weight: 500; }

b, th, strong {
  font-weight: 500;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: -1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    animation-delay: 0s !important;
    transition-delay: 0s !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
  }
}


/* utilites */

.color-white { color: white; }
.color-grey-1 { color: var(--color-grey-1); }
.color-grey-2 { color: var(--color-grey-2); }
.color-grey-3 { color: var(--color-grey-3); }
.color-light-grey { color: var(--color-light-grey); }
.color-green { color: var(--color-green); }
.color-red { color: var(--color-red); }
.color-yellow { color: var(--color-yellow); }

.medium, w-500 { font-weight: 500; }
.bold, w-700 { font-weight: 700; }

.fs-10 { font-size: 1rem; }
.fs-12 { font-size: 1.2rem; }
.fs-14 { font-size: 1.4rem; }
.fs-16 { font-size: 1.6rem; }

.uppercase { text-transform: uppercase; }
.underline { text-decoration: underline; }
.hover\:underline:hover { text-decoration: underline; }

.focus\:outline:focus { outline: 1px dotted var(--color-light-grey); }

.mb-10 { margin-bottom: 1rem; }
.mb-10 { margin-bottom: 1rem; }


.customTooltip * {
  /*color: #fff;*/
  color: #1c1c1c;
  /*background-color: rgba(000, 0, 0, 0.7);*/
  font-family: Inter, sans-serif;
  font-size: 18px;
}

.introjs-tooltip  {
  border-radius: 0 !important;
  max-width: 650px !important;
}

.introjs-tooltip-header {
  padding: 0 !important;
}

.introjs-tooltip-header p {
  padding: 20px 0 0 20px !important;
}

.introjs-tooltip-header img {
  width: 600px;
}

.customTooltip .introjs-nextbutton {
  border: none;
  padding: 5px 20px;
  background: #00EB96;
  box-shadow: none;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  border-radius: 0px;
  text-shadow: none;
}
.customTooltip .introjs-nextbutton:hover, .customTooltip .introjs-nextbutton:focus, .customTooltip .introjs-nextbutton:active {
  background: #00EB96;
  border: none;
}

.customTooltip .introjs-prevbutton {
  border: none;
  padding: 5px 20px;
  background: #666666;
  box-shadow: none;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  border-radius: 0px;
  text-shadow: none;
}

.introjs-helperLayer {
  box-shadow: rgb(33 33 33 / 80%) 0px 0px 1px 2px, rgb(0 0 0 / 65%) 0px 0px 0px 5000px !important;
}

.customTooltip .introjs-prevbutton:hover, .customTooltip .introjs-prevbutton:focus, .customTooltip .introjs-prevbutton:active {
  background: #666666;
  border: none;
  color: #fff;
}

.customTooltip .introjs-progress {
  border-radius: 0;
}

.customTooltip .introjs-progressbar {
  background: #00EB96;
}

.share-modal > div > div:nth-child(2) {
  margin-top: 90px;
  display: flex;
}

.share-modal > div > div:nth-child(3) >div:nth-child(1) {
  font-size: 12px !important;
  font-family: 'Inter', sans-serif !important;
  overflow: hidden;
}

.share-modal > div > div:nth-child(3) >div:nth-child(2) {
  color: #FF0099 !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 12px !important;
}

*::-webkit-scrollbar {
  background: #3F3F3F;
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  background: #8B8B8B;
}

.intercom-launcher {
  display: none;
}