#__modals {}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  transition: .225s background;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.backdrop[data-transition-state=entering] {
  background: rgba(0, 0, 0, 0);
}
.backdrop[data-transition-state=enter] {
  background: rgba(0, 0, 0, 0.65);
}
.backdrop[data-transition-state=exiting] {
  background: rgba(0, 0, 0, 0.65);
}
.backdrop[data-transition-state=exit] {
  background: rgba(0, 0, 0, 0);
}

.backdrop > * {
  transition-duration: .225s;
  transition-property: opacity, transform;
}

.backdrop[data-transition-state=entering] > * {
  transform: scale(.9);
  opacity: 0;
}
.backdrop[data-transition-state=enter] > * {
  transform: scale(1);
  opacity: 1;
}
.backdrop[data-transition-state=exiting] > * {
  transform: scale(1);
  opacity: 1;
}

.backdrop[data-transition-state=exit] > * {
  transform: scale(.9);
  opacity: 0;
}
